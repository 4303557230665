import React from 'react'
import Helmet from 'react-helmet'
import SlideToggle from 'react-slide-toggle'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

const About = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="faq" lang="ja" />
        <title>{`よくある質問 | ${title}`}</title>
        <meta
          name="description"
          content="お客様から多く寄せられる内容について、お問い合わせの前にぜひ一度ご確認ください。"
        />
      </Helmet>
      <div className="common-header clearfix">
        <h1>よくあるご質問</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>よくあるご質問</li>
        </div>
      </div>

      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            <ul className="toggle-faq">
              <SlideToggle
                collapsed
                render={({ toggle, setCollapsibleElement, toggleState }) => (
                  <li className="my-collapsible">
                    <div
                      className={`question ${
                        toggleState === 'EXPANDED' && 'opened'
                      }`}
                      onClick={toggle}
                      aria-hidden="true"
                    >
                      ロック板がないと、お客様が未払いのままうっかり出庫してしまうようなことはありませんか？
                    </div>
                    <div
                      className="my-collapsible__content"
                      ref={setCollapsibleElement}
                    >
                      <div className="my-collapsible__content-inner">
                        <div className="answer">
                          車室後方に設置されたナンバー認識ポールには、「課金中」あるいは「精算済」の表示が出ます。「課金中」の表示中に、お客様が運転席ドアに近づくと、お支払いを促すアナウンスが流れ、未払いのまま出庫しないよう注意をうながします。それでも、万が一、うっかり出庫してしまった場合は、コールセンターが精算機を遠隔操作し、出庫後のお支払い手続きをサポートします。
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              />
              <SlideToggle
                collapsed
                render={({ toggle, setCollapsibleElement, toggleState }) => (
                  <li className="my-collapsible">
                    <div
                      className={`question ${
                        toggleState === 'EXPANDED' && 'opened'
                      }`}
                      onClick={toggle}
                      aria-hidden="true"
                    >
                      悪質な不払い出庫が発生した場合は？
                    </div>
                    <div
                      className="my-collapsible__content"
                      ref={setCollapsibleElement}
                    >
                      <div className="my-collapsible__content-inner">
                        <div className="answer">
                          アイテックのコールセンターでは、ナンバー認識システムと場内の防犯カメラを利用して、不払いのまま出庫した車両を特定することができます。万が一、悪質な不払い出庫が発生した場合は、後日、請求することができます。
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              />
              <SlideToggle
                collapsed
                render={({ toggle, setCollapsibleElement, toggleState }) => (
                  <li className="my-collapsible">
                    <div
                      className={`question ${
                        toggleState === 'EXPANDED' && 'opened'
                      }`}
                      onClick={toggle}
                      aria-hidden="true"
                    >
                      場内で起きた事故、不正行為、トラブルなどが発生したときの対応は？
                    </div>
                    <div
                      className="my-collapsible__content"
                      ref={setCollapsibleElement}
                    >
                      <div className="my-collapsible__content-inner">
                        <div className="answer">
                          駐車場（精算機、防犯カメラ等）とコールセンター間は、インターネット回線を使ったネットワークで24時間365日結ばれているため、場内で発生したトラブルやお客様からのご依頼・クレームにはスピーディに適切な対応を行うことができます。
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              />
              <SlideToggle
                collapsed
                render={({ toggle, setCollapsibleElement, toggleState }) => (
                  <li className="my-collapsible">
                    <div
                      className={`question ${
                        toggleState === 'EXPANDED' && 'opened'
                      }`}
                      onClick={toggle}
                      aria-hidden="true"
                    >
                      土地が砂利敷のためロック板が設置できないのだが。
                    </div>
                    <div
                      className="my-collapsible__content"
                      ref={setCollapsibleElement}
                    >
                      <div className="my-collapsible__content-inner">
                        <div className="answer">
                          ご安心ください。ロックレスなら状態によっては砂利敷の土地でも設置可能です。
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              />
              <SlideToggle
                collapsed
                render={({ toggle, setCollapsibleElement, toggleState }) => (
                  <li className="my-collapsible">
                    <div
                      className={`question ${
                        toggleState === 'EXPANDED' && 'opened'
                      }`}
                      onClick={toggle}
                      aria-hidden="true"
                    >
                      クレーム対応を委託する事は可能でしょうか？
                    </div>
                    <div
                      className="my-collapsible__content"
                      ref={setCollapsibleElement}
                    >
                      <div className="my-collapsible__content-inner">
                        <div className="answer">
                          はい。アイテックのコールセンターは365日24時間体制で管理委託を受けた駐車場とつながっていますから、利用者様からの一次的なご依頼やクレームに迅速に対応することができます。対応後には報告書を作成・送付させていただきます。
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              />
              <SlideToggle
                collapsed
                render={({ toggle, setCollapsibleElement, toggleState }) => (
                  <li className="my-collapsible">
                    <div
                      className={`question ${
                        toggleState === 'EXPANDED' && 'opened'
                      }`}
                      onClick={toggle}
                      aria-hidden="true"
                    >
                      現金以外の精算は何に対応していますか？
                    </div>
                    <div
                      className="my-collapsible__content"
                      ref={setCollapsibleElement}
                    >
                      <div className="my-collapsible__content-inner">
                        <div className="answer">
                          ネットワーク対応精算機は様々なキャッシュレス精算に対応しております。駐車場の立地などによりお選び頂けます。
                          <ul>
                            <li>
                              クレジットカード（VISA/Master/JCB/AMEX/Diners・ICカード対応）
                            </li>
                            <li>電子マネー（交通系・流通系など）</li>
                            <li>
                              バーコード決済（PayPay・ｄ払い・au
                              PAY・メルペイ・LINE Pay）
                            </li>
                            <li>ｄポイント</li>
                            <li>
                              QT-net（スマホアプリ精算・ポイントカード精算など）
                            </li>
                            <li>外部決済（Peasy・SmooPA・SmartParking）</li>
                            <li>サービス券</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              />
              <SlideToggle
                collapsed
                render={({ toggle, setCollapsibleElement, toggleState }) => (
                  <li className="my-collapsible">
                    <div
                      className={`question ${
                        toggleState === 'EXPANDED' && 'opened'
                      }`}
                      onClick={toggle}
                      aria-hidden="true"
                    >
                      時間貸し駐車場に適した土地を持っているが、自分で運営まではやりたくない。
                    </div>
                    <div
                      className="my-collapsible__content"
                      ref={setCollapsibleElement}
                    >
                      <div className="my-collapsible__content-inner">
                        <div className="answer">
                          ご安心ください。地主様のご要望に合わせた多種多様なご提案をさせていただきます。まずはお問い合わせください。
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              />
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
